<template>
  <div>
    <div style="overflow: scroll;">
      <table
        class="table b-table table-fixed"
        :style="result.total == 0 ? '' : 'width: 1560px;'"
      >
        <thead>
          <tr>
            <th>Nomor Invoice</th>
            <th>Buyer</th>
            <th>Talenta</th>
            <th>Nama Paket</th>
            <th>Nama</th>
            <th>Status</th>
            <th>Total</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoadingComponent">
            <td
              colspan="8"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in resultData.data"
            v-else
            :key="item.uuid"
          >
            <td style="width: 18%;">
              <span class="text-danger">
                {{ item.invoice_number ? item.invoice_number : '-' }}<br>
              </span>
              <span class="text-success">
                {{ item.talent_invoice_number ? item.talent_invoice_number : '-' }}
              </span>
            </td>
            <td>
              <a
                v-if="item.buyer"
                class="text-primary"
                @click="goToDetail('users.show', item.buyer.uuid)"
              >
                {{ item.buyer.name ? item.buyer.name : '-' }}
              </a>
            </td>
            <td>
              <a
                v-if="item.talent"
                class="text-primary"
                @click="goToDetail('talenta.show', item.talent.uuid)"
              >
                {{ item.talent.name ? item.talent.name : '-' }}
              </a>
            </td>
            <td>
              {{ item.package_name ? item.package_name : '-' }}
            </td>
            <td>
              {{ item.service_name ? item.service_name : '-' }}
            </td>
            <td>
              {{ item.status_copy ? item.status_copy : '-' }}
            </td>
            <td>
              {{ item.total ? item.total : '-' | formatAmount }}
            </td>
            <td>
              <b-dropdown
                variant="info"
                size="sm"
              >
                <template #button-content>
                  Action
                </template>
                <b-dropdown-item
                  v-if="checkPermission('detail order')"
                  @click="detailOrder(item.uuid)"
                >
                  <feather-icon
                    icon="UsersIcon"
                    class="mr-75"
                  />
                  Detail Order
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
          <tr v-if="resultData.total == 0 && !isLoadingComponent">
            <td
              colspan="8"
              class="text-center"
            >
              <span>
                Data is empty.
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="resultData.total > 0"
      class="m-1"
    >
      <div class="row">
        <div class="col mb-1">
          <small>Showing {{ resultData.from }} to {{ resultData.to }} from {{ resultData.total }}</small>
        </div>
        <div class="col">
          <pagination
            :data="resultData"
            :limit="4"
            align="right"
            @pagination-change-page="getData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BSpinner,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    result: {
      type: Object,
    },
    getData: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoadingComponent: true,
      resultData: {},
    }
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value
    },
    result(value) {
      this.resultData = value
    },
  },
  methods: {
    detailOrder(id) {
      this.$router.push({ name: 'order.show', params: { id } })
    },
    goToDetail(routeName, id) {
      this.$router.push({ name: routeName, params: { id } })
    },
  },
}
</script>
