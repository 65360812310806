<template>
  <b-card-code
    no-body
    title="List Order"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <b-row>
        <b-col cols="12">
          <div class="form-row justify-content-end mb-1">
            <b-col
              cols="12"
              lg="4"
              class="mb-1 mb-lg-0"
            >
              <input
                v-model="filter.keyword"
                type="text"
                placeholder="Pencarian"
                class="form-control form-control-sm float-right"
              >
            </b-col>
          </div>
        </b-col>
      </b-row>
    </div>
    <ListOrder
      :result="result"
      :is-loading="isLoading"
      :get-data="getData"
    />
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import ListOrder from '@/components/Order/ListOrder.vue'

export default {
  title() {
    return 'List Order'
  },
  components: {
    BRow,
    BCol,
    BCardCode,
    ListOrder,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      isLoading: true,
      result: {},
      filter: {
        keyword: '',
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/order', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
.table > tbody > tr > td {
  max-width: 300px;
}
</style>
